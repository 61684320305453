<template>
    <v-progress-circular v-if="fetchingBranchData || fetchingVerificationItems" class="center-center" indeterminate/>
    <v-container v-else fluid>
        <v-row>
            <v-col class="text-start">
                <v-tooltip right>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn v-bind="attrs" v-on="on" icon @click="backToSites">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.pages.site.backToSiteUnverifiedBranches') }}</span>
                </v-tooltip>
            </v-col>
            <v-col class="text-end" cols="auto">
                <kurcc-user-options/>
            </v-col>
        </v-row>
        <v-card outlined>
            <v-card-text>
                <v-row align="center">
                    <v-col cols="12">
                        <v-card-title class="red--text text--darken-4">
                            {{ $vuetify.lang.t('$vuetify.pages.site.generalInfo') }}
                        </v-card-title>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="6">
                                <v-row align="center" dense justify="top">
                                    <v-col cols="6">
                                        <v-text-field v-model="branchData.number"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.branchNumber')"
                                                      :loading="fetchingBranchData" hide-details
                                                      outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle :branch-id="branchData.id"
                                                                   :initial-value.sync="verification_items.number"
                                                                   :site-id="branchData.site.id"
                                                                   field-name="number"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="branchData.site.id"
                                        :branch-id="branchData.id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="number"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="branchData.brand_name_en"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.dataTableHeaders.brandNameEn')"
                                                      :loading="fetchingBranchData" hide-details
                                                      outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :branch-id="branchData.id"
                                            :initial-value.sync="verification_items.brand_name_en"
                                            :site-id="branchData.site.id"
                                            field-name="brand_name_en"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="branchData.site.id"
                                        :branch-id="branchData.id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="brand_name_en"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="branchData.brand_name_ar"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.dataTableHeaders.brandNameAr')"
                                                      :loading="fetchingBranchData" hide-details
                                                      outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :branch-id="branchData.id"
                                            :initial-value.sync="verification_items.brand_name_ar"
                                            :site-id="branchData.site.id"
                                            field-name="brand_name_ar"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="branchData.site.id"
                                        :branch-id="branchData.id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="brand_name_ar"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="branchData.manager_name"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.managerName')"
                                                      :loading="fetchingBranchData" hide-details
                                                      outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :branch-id="branchData.id"
                                            :initial-value.sync="verification_items.manager_name"
                                            :site-id="branchData.site.id" field-name="manager_name"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="branchData.site.id"
                                        :branch-id="branchData.id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="manager_name"/>
                                </v-row>
                            </v-col>
                            <v-col cols="6">
                                <v-row align="center" dense justify="top">
                                    <v-col cols="6">
                                        <v-text-field v-model="branchData.manager_phone_number"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.managerPhoneNumber')"
                                                      :loading="fetchingBranchData" hide-details
                                                      outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :branch-id="branchData.id"
                                            :initial-value.sync="verification_items.manager_phone_number"
                                            :site-id="branchData.site.id"
                                            field-name="manager_phone_number"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="branchData.site.id"
                                        :branch-id="branchData.id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="manager_phone_number"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="branchData.area"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.area')"
                                                      :loading="fetchingBranchData" hide-details
                                                      outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :branch-id="branchData.id"
                                            :initial-value.sync="verification_items.area"
                                            :site-id="branchData.site.id" field-name="area"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="branchData.site.id"
                                        :branch-id="branchData.id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="area"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="branchData.address"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.area')"
                                                      :loading="fetchingBranchData" hide-details
                                                      outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :branch-id="branchData.id"
                                            :initial-value.sync="verification_items.address"
                                            :site-id="branchData.site.id" field-name="address"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="branchData.site.id"
                                        :branch-id="branchData.id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="address"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="branchData.youtube_url"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.youtubeURL')"
                                                      :loading="fetchingBranchData" hide-details
                                                      outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :branch-id="branchData.id"
                                            :initial-value.sync="verification_items.youtube_url"
                                            :site-id="branchData.site.id" field-name="youtube_url"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="branchData.site.id"
                                        :branch-id="branchData.id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="youtube_url"/>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import Branch from "@/modules/sites/models/branch";

export default {
    name: "KurccBranchVerificationPage",
    components: {
        KurccUserOptions: () => import('@/modules/app/components/KurccUserOptions'),
        KurccVerificationToggle: () => import('@/modules/verifications/components/KurccVerificationToggle'),
        KurccRejectionReason: () => import('@/modules/verifications/components/KurccRejectionReason')
    },
    props: ['branch', 'branch_id'],
    data() {
        return {
            branchData: new Branch(),
            verification_items: null,
            fetchingVerificationItems: false,
            fetchingBranchData: false
        }
    },
    methods: {
        fetchBranchData() {
            this.fetchingBranchData = true
            this.$store.dispatch('getBranch', {id: this.branch_id})
                .then(res => {
                    this.branchData = res
                    this.getVerificationItems()
                }).finally(() => {
                this.fetchingBranchData = false
            })
        },
        backToSites() {
            this.$router.push({
                name: 'site-unverified-branches',
                params: {site_id: this.branchData.site.id, site: this.branchData.site}
            })
        },
        getVerificationItems() {
            this.fetchingVerificationItems = true
            this.$store.dispatch('getVerificationItems', {site_id: this.branchData.site.id, branch_id: this.branch_id})
                .then(res => {
                    this.verification_items = res
                }).finally(() => {
                    this.fetchingVerificationItems = false
                }
            )
        }
    },
    created() {
        if (this.branch) {
            if (this.branch.site) {
                this.branchData = this.$lodash.cloneDeep(this.branch)
                this.getVerificationItems()
            } else this.fetchBranchData()
        } else this.fetchBranchData()
    }
}
</script>

<style lang="scss" scoped>
.w-100 {
    width: 100%;
}
</style>
